(function() {

    'use strict';

    /**
     * This is the content of the Video.js bundle, which decides what player version to serve based on browser features detection.
     */


    // already defined? exit!
    if (window.SAPO && (
             window.SAPO._loadingVideoBundle ||
            (window.SAPO.Project && window.SAPO.Project.Video) ) ) {
        return;
    }



    if (typeof SAPO === 'undefined') {
        window.SAPO = {
            _loadingVideoBundle: true
        };
    }
    else {
        window.SAPO = window.SAPO;
    }


    // detect video element support
    var supportsVideoElement = false;
    var canPlayH264 = false;
    (function() {
        var el = document.createElement('video');
        supportsVideoElement = !!el.play;
        if (supportsVideoElement) {
            canPlayH264 = !!( el.canPlayType('video/mp4; codecs="avc1.42E01E"').replace(/^no$/, '') );
        }
    })();



    // detect flash support
    var hasFlash;
    (function() {
        /*global ActiveXObject:false */
        // http://www.jquery4u.com/snippets/jquery-check-flash-enabled/
        // https://code.google.com/p/swfobject/source/browse/trunk/swfobject/src/swfobject.js line 48
        var hf = false;
        try {
            var fo = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
            if (fo) { hf = true; }
        }
        catch (ex) {
            try {
                var plug = navigator.mimeTypes['application/x-shockwave-flash'];
                if (plug && plug.enabledPlugin) {
                    hf = true;
                }
            }
            catch (ex2) {}
        }
        hasFlash = hf;
    })();



    var bundle = 'VideoHighEnd';
    var ua = navigator.userAgent.toLowerCase();

    //if (/(msie)/i.test(ua) && ua.replace(/(.*)\smsie\s([^;]+);(.*)/, '$2') < 8) { // SAPO.Browser.IE && SAPO.Browser.version < 8
    if ( (!supportsVideoElement && hasFlash) &&
         (/(msie)/i.test(ua) && parseFloat(ua.replace(/(.*)\smsie\s([^;]+);(.*)/, '$2')) < 8) // SAPO.Browser.IE && SAPO.Browser.version < 8
    ) {
        bundle = 'VideoFlash';
    }


    var headEl = document.getElementsByTagName('head')[0];
    var bodyEl = document.getElementsByTagName('body')[0];


    var delayedLoadVar;


    var getFullSrc = function(scriptEl) {
        var src = scriptEl.src;
        if (src.indexOf('://') === -1) {
            src = '//' + location.host + src;
        }
        return src;
    };



    var getScriptParts = function(src) {
        try {
            var hRgx = new RegExp('\/\/([^\/]+)', 'g');
            var vRgx = new RegExp('([^\/]+)\/Video.js', 'g');

            var host    = hRgx.exec(src)[1];
            var version = vRgx.exec(src)[1];

            var pRgx = new RegExp(host.replace(/\./g, '\\.') + '(.+)' + version.replace(/\./g, '\\.').replace(/\//g, '\/'), 'g');

            var path = pRgx.exec(src)[1];

            return [host, path, version];
        } catch (ex) { return; }
    };


    // determine domain and location of this script in the HTML document
    var fetchVars = function() {
        var parseAttributes = function(rootEl) {
            if (!rootEl) { return; }
            var m, i, src, scriptEl;
            var scriptEls = rootEl.getElementsByTagName('script');

            for (i = scriptEls.length - 1 ; i >= 0; --i) {
                scriptEl = scriptEls[i];
                src = getFullSrc(scriptEl);
                m = getScriptParts(src);
                if (m) {
                    delayedLoadVar = scriptEl.getAttribute('data-delayed-load');
                    var implementations = scriptEl.getAttribute('data-implementations');

                    if (implementations) {
                        implementations = implementations.split(/,\s*/);
                        for (var j = 0, J = implementations.length, impl; j < J; ++j) {
                            impl = implementations[j].toLowerCase().replace(/^\s+|\s+$|\n+$/g, ''); // trim polyfill inline
                            implementations[j] = impl;
                        }

                        window.SAPO._videoImplementations = implementations;
                    }

                    return m;
                }
            }
        };

        var hostAndVersion = parseAttributes(headEl);
        if (!hostAndVersion) {
            hostAndVersion = parseAttributes(bodyEl);
        }

        return hostAndVersion;
    };


    /*global SV_HOST:true, SV_VERSION:true, SV_PATH:true */
    if (!SV_HOST) {
        var tmp = fetchVars();
        var path = tmp[1];
        SV_HOST    = tmp[0];
        SV_VERSION = tmp[2];
        SV_PATH    = SV_HOST + path + SV_VERSION;
    }


    var loadResources = function() {
        // fetch bundle...
        var scriptEl = document.createElement('script');
        scriptEl.setAttribute('type', 'text/javascript');
        scriptEl.setAttribute('data-sapo-videos-player', [SV_HOST, SV_PATH, SV_VERSION].join(' '));
        scriptEl.setAttribute('src', ['//', SV_PATH, '/', bundle, '.js'].join(''));
        //console.log(scriptEl);


        var linkEl;
        if (document.readyState !== 'complete' && !document.body) {
            document.write(scriptEl.outerHTML);
            if (linkEl) {
                document.write(linkEl.outerHTML);
            }
        }
        else {
            headEl.appendChild(scriptEl); // REMEMBER THIS IS ASYNCHRONOUS!

            if (linkEl) {
                headEl.appendChild(linkEl);
            }
        }
    };

    if (delayedLoadVar) {
        window[delayedLoadVar] = {
            bundle: bundle,

            load: function(cb) {
                var timer;
                var checkReady = function() {
                    if (!(window.SAPO && SAPO.Project && SAPO.Project.Video)) { return; }
                    clearInterval(timer);

                    // WOW IE8 - http://stackoverflow.com/questions/1073414/deleting-a-window-property-in-ie
                    window[delayedLoadVar] = undefined;
                    try {
                        delete window[delayedLoadVar];
                    } catch (ex) {}

                    cb();
                };

                loadResources();
                timer = setInterval(checkReady, 500);
            }
        };
    }
    else {
        loadResources();
    }



})();
